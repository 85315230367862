import "@babel/polyfill";

import React from "react";
import ReactHabitat from "react-habitat";
import { configureStore, getDefaultMiddleware } from "redux-starter-kit";
import createSagaMiddleware from "redux-saga";

import * as client from "../cjs/client";
import { reducers, rootSaga } from "../cjs/redux";
import { CustomerPortalDomFactory } from "../cjs/page";

class MyApp extends ReactHabitat.Bootstrapper {
  constructor() {
    super();

    const sagaMiddleware = createSagaMiddleware();

    const store = configureStore({
      reducer: reducers.reducers,
      middleware: [sagaMiddleware, ...getDefaultMiddleware()],
    });

    sagaMiddleware.run(rootSaga);

    // Create a new container builder:
    const builder = new ReactHabitat.ContainerBuilder();

    builder.factory = new CustomerPortalDomFactory(store);

    // Register a components:
    for (let key in client) {
      if (client.hasOwnProperty(key)) {
        builder.register(client[key]).as(key);
      }
    }

    // Finally, set the container:
    this.setContainer(builder.build());

    //For XPM buttons to not be off the page.
    if (window.top !== window.self) {
      document.body.style.padding = "15px";
    }
  }
}

// Always export a 'new' instance so it immediately evokes:
export default new MyApp();
