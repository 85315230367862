'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

require('./index-bfa3a5a7.js');
var rootSaga = require('./rootSaga-905fb1af.js');



exports.reducers = rootSaga.reducers$1;
exports.rootSaga = rootSaga.rootSaga;
